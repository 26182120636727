import React, {
  useEffect,
  useState,
  useContext,
  useReducer,
  useCallback
} from 'react'
import styled from 'styled-components'
import {
  map,
  path,
  uniq,
  o,
  unnest,
  compose,
  filter,
  contains,
  last,
  anyPass,
  split,
  applySpec,
  sortBy,
  groupBy,
  head,
  toPairs,
  evolve,
  length,
  intersection,
  append,
  init,
  pathEq,
  always,
  not,
  unless,
  join,
  T,
  toLower,
  reduce,
  find,
  isEmpty,
  lt,
  __,
  assoc,
  flatten,
  pick,
  prop,
  values,
  trim,
  useWith,
  memoizeWith
} from 'ramda'
import Grid from '@material-ui/core/Grid'
import Select from '@material-ui/core/Select'
import InputLabel from '@material-ui/core/InputLabel'
import Input from '@material-ui/core/Input'
import InputAdornment from '@material-ui/core/InputAdornment'
import TextField from '@material-ui/core/TextField'
import Paper from '@material-ui/core/Paper'
import Chip from '@material-ui/core/Chip'
import Search from '@material-ui/icons/Search'
import MenuItem from '@material-ui/core/MenuItem'
import NavContext from 'components/NavProvider'
import Image from 'components/Image'
import Link from 'components/Link'
import { baseColors } from 'utils/theme'
import { color } from 'utils/style'

const gtmPush=memoizeWith(prop('id'),download=>()=>{
  dataLayer.push({event:'download', downloadSlug:download.slug})
})

const Background = styled.div`
  && {
    padding-top: 5px;
  }
  background-color: white;
  z-index: 501;
`

const Content = styled.div`
  padding: 16px;
  height: 170px;
  display: flex;
  flex-direction: ${({ row }) => (row ? 'row' : 'column')};
  justify-content: ${({ justify }) => justify || 'space-between'};
  > a {
    height: 100%;
  }
`

const Container = styled(Grid)``

const SelectContainer = styled.div`
  display: flex;
  align-items: center;
`

const StyledInputLabel = styled(InputLabel)`
  padding-right: 16px;
`

const StyledSelect = styled(Select)`
  flex: 1;
  background-color: white;
`
const StyledInput = styled(Input)`
  background-color: white;
`

const Item = styled(Grid)`
  :nth-child(even) {
    ${Content} {
      background-color: ${({ theme }) => theme.colors[theme.primary].color};
      color: ${({ theme }) => theme.colors[theme.primary].contrast};
      .title {
        color: ${({ theme }) => theme.colors[theme.primary].highlight};
      }
    }
  }
  :nth-child(odd) {
    ${Content} {
      background-color: ${({ theme }) => theme.colors[theme.secondary].color};
      color: ${({ theme }) => theme.colors[theme.secondary].contrast};
      .title {
        color: ${({ theme }) => theme.colors[theme.secondary].highlight};
      }
    }
  }
  :first-child {
    ${Content} {
      background-color: ${({ theme }) => theme.colors.black.color};
      color: ${({ theme }) => theme.colors.black.contrast};
      h1 {
        color: ${({ theme }) => theme.colors.black.highlight};
      }
      .subline,
      .roofline {
        color: ${({ theme }) => theme.colors.black.contrast};
      }
    }
  }
`

const Autocomplete = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  > div:first-child {
    + * {
      display: none;
    }
    :focus-within {
      + * {
        display: initial;
      }
    }
  }
`

const Suggestions = styled(Paper)`
  z-index: 501;
  position: absolute;
  top: 48px;
`

const SubMenuItem = styled(MenuItem)`
  padding-left: 32px;
`

const Download = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
`
const Description = styled.div`
  width: 62.8%;
  height: 100%;
`

export default function StoryblokDownloads ({ data }) {
  const { lang, history } = useContext(NavContext) || {}
  const [{
    downloads, query, selectedGroups, suggest
  }, setState] = useReducer(
    (state, action) => {
      const newState = { ...state, ...action }
      const allTags = map(
        toLower,
        uniq(
          flatten(
            map(
              compose(
                values,
                pick(['group', 'tags']),
                prop('component')
              ),
              newState.downloads
            )
          )
        )
      )
      const keywords = compose(
        reduce(
          (acc, value) =>
            (last(acc) && find(contains(join(' ', [last(acc), value])), allTags)
              ? append(join(' ', [last(acc), value]), init(acc))
              : find(contains(value), allTags)
                ? append(value, acc)
                : acc),
          []
        ),
        filter(o(lt(2), length)),
        split(' '),
        toLower
      )(newState.query)

      return {
        ...newState,
        suggest: applySpec({
          keywords: compose(
            filter(o(anyPass(map(contains, keywords)), toLower)),
            uniq,
            unnest,
            map(path(['component', 'tags']))
          ),
          groups: compose(
            filter(o(anyPass(map(contains, keywords)), toLower)),
            x => console.log(x, keywords) || x,
            uniq,
            unnest,
            map(path(['component', 'group']))
          ),
          downloads: compose(
            sortBy(path(['component', 'tags'])),
            filter(path(['component', 'tags'])),
            map(
              evolve({
                component: {
                  tags: compose(
                    length,
                    unless(
                      always(isEmpty(keywords)),
                      filter(anyPass(map(contains, keywords)))
                    ),
                    map(toLower)
                  )
                }
              })
            ),
            unless(
              always(isEmpty(newState.selectedGroups)),
              filter(
                o(
                  anyPass(map(contains, newState.selectedGroups)),
                  path(['component', 'group'])
                )
              )
            )
          )
        })(newState.downloads)
      }
    },
    {
      downloads: [],
      selectedGroups: [],
      query: history?.slice(-1)?.[0]?.context?.downloadQuery || '',
      suggest: {}
    }
  )
  const [selectedType, setType] = useState()

  const getDownloads = async () => {
    try {
      const json = await import(`../../../.temp/${lang}/downloads.json`)
      setState({
        downloads: map(
          evolve({
            component: {
              group: o(map(trim), split(',')),
              tags: o(map(trim), split(','))
            }
          }),
          filter(path(['component', 'file', 'url']), json.default)
        )
      })
    } catch (e) {
      console.log(e)
    }
  }

  useEffect(
    () => {
      getDownloads()
    },
    [lang]
  )

  const updateQuery = useCallback((e) => {
    setState({ query: e.target.value })
  }, [])

  useEffect(() => {}, [query, downloads, selectedGroups])

  const completeKeyword = keyword => () => {
    compose(
      useWith(setState, [assoc('query', __, {})]),
      join(' '),
      append(keyword),
      init,
      split(' ')
    )(query)
  }

  const setGroup = (group, bool) => () => {
    setState({
      groups: bool
        ? uniq(append(group, selectedGroups))
        : filter(g => g !== group, selectedGroups)
    })
  }

  return (
    <Background>
      <Container spacing={1} container>
        <Item md={6} xs={12} item>
          <Content justify="center">
            {data.roofline && <div className="roofline">{data.roofline}</div>}
            <h1>{data.title}</h1>
            <div className="subline">{data.subline}</div>
          </Content>
        </Item>
        <Item md={6} xs={12} item>
          <Content justify="space-evenly">
            <SelectContainer>
              <StyledInputLabel htmlFor="type">
                {data.typeTranslation}
              </StyledInputLabel>
              <StyledSelect
                input={<StyledInput id="type" />}
                displayEmpty
                value={selectedType}
                onChange={o(setType, path(['target', 'value']))}
              >
                <MenuItem value="">{data.anyTranslation}</MenuItem>
                {map(
                  type => (
                    <MenuItem key={type} value={type}>
                      {type}
                    </MenuItem>
                  ),
                  uniq(map(path(['component', 'type']), downloads))
                )}
              </StyledSelect>
            </SelectContainer>

            {!isEmpty(selectedGroups) && (
              <SelectContainer>
                <StyledInputLabel>{data.groupsTranslation}</StyledInputLabel>
                <Paper>
                  {map(
                    group => (
                      <Chip
                        key={group}
                        label={group}
                        onDelete={setGroup(group, false)}
                      />
                    ),
                    selectedGroups
                  )}
                </Paper>
              </SelectContainer>
            )}

            <Autocomplete>
              <TextField
                fullWidth
                placeholder={data.searchPlaceholder}
                onChange={updateQuery}
                value={query}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <Search />
                    </InputAdornment>
                  ),
                  style: { backgroundColor: 'white' }
                }}
              />
              {/* suggest.keywords?.concat(suggest.group || []).length > 0 && (
                <Suggestions>
                  <MenuItem selected>{data.keywordsTranslation}</MenuItem>
                  {map(
                    keyword => (
                      <SubMenuItem
                        key={keyword}
                        onClick={completeKeyword(keyword)}
                      >
                        {keyword}
                      </SubMenuItem>
                    ),
                    suggest.keywords
                  )}
                  <MenuItem selected>{data.groupsTranslation}</MenuItem>
                  {map(
                    group => (
                      <SubMenuItem key={group} onClick={setGroup(group, true)}>
                        {group}
                      </SubMenuItem>
                    ),
                    suggest.groups
                  )}
                </Suggestions>
              ) */}
            </Autocomplete>
          </Content>
        </Item>
        {compose(
          map(([group, dls]) => (
            <>
              <Item key={group} xs={12}>
                {group}
              </Item>
              {map(
                download => (
                  <Item xl={4} md={6} xs={12} item key={download.id}>
                    <Content>
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href={download.component.slug || download.component.file.localFile?.publicURL}
                        onClick={gtmPush(download)}
                      >
                        <Download>
                          <Image
                            style={{ width: '37.8%' }}
                            src={download.component.image}
                            alt={download.component.imageAlt}
                            title={download.component.imageTitle}
                            description={download.component.imageDescription}
                          />
                          <Description>
                            <div className="title">
                              {download.component.name}
                            </div>
                            <p>{download.component.description}</p>
                          </Description>
                        </Download>
                      </a>
                    </Content>
                  </Item>
                ),
                dls
              )}
            </>
          )),
          sortBy(head),
          toPairs,
          groupBy(o(join(' > '), path(['component', 'group']))),
          unless(
            always(not(selectedType)),
            filter(pathEq(['component', 'type'], selectedType))
          )
        )(suggest.downloads || downloads)}
      </Container>
    </Background>
  )
}
